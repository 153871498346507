import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Kilo from "../assets/images/kilo.jpg"

import LeaderScroller from "../components/LeaderScroller"

import LogoWhite from "../images/Logo-white.svg"
import DearLeader from "../images/DearLeaderWhite.png"
import Swipe from "../components/swipe"

const Container = styled.div`
  min-height: 300px;
  width: 100%;

  background-color: ${props => props.backgroundColor};
    ${props => props.mobilePadding && `padding: ${props.mobilePadding};`}
    ${props =>
      props.centerItems &&
      `display: flex;
      align-items: center;`}
    ${props => props.hideOnMobile && "display: none;"} @media screen and
    (min-width: 30em) {
    ${props => props.padding && `padding: ${props.padding};`}
  }
`

const Introduction = styled.h1`
  text-align: center;
  font-size: 24px;
  font-family: "MissionGothicBold", sans-serif;
  line-height: 1.5;
  margin-bottom 24px;
  padding: 0px 24px;
  color: ${props => props.color};
  margin-top: 8px;

  @media screen and (min-width: 30em) {
    font-size: 32px;
  }
`

const Paragraph = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: "MissionGothicBold", sans-serif;
  line-height: 1.5;
  margin-bottom 32px;
  color: ${props => props.color};
  padding: 0px 24px;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 30em) {
    padding: 0px 80px;
    font-size: 24px;
  }

  @media screen and (min-width: 60em) {
    padding: 0px 80px;
  }
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Video = styled.video`
  width: 100%;
  height: auto;
  margin-bottom: -5px;
`

const Title = styled.div`
  text-align: center;
  margin-bottom: 8px;
  padding: 0px 20px;
  font-size: 24px;
  line-height: 1.5;
  font-family: "MissionGothicBold", sans-serif;
`

const Section = styled.div`
  width: 100%;
  ${props =>
    !props.noMinHeight &&
    `${props.minHeight ? `height: ${props.minHeight}px;` : "100vh;"}
  min-height: ${props.minHeight}px;`}

  padding: 40px 0px;

  @media screen and (min-width: 30em) {
    padding: 60px 0px;
  }

  ${props =>
    !props.noFlex &&
    `display: flex;
  align-items: center;
  text-align: center;`}

  ${props =>
    props.backgroundImage &&
    `background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  background-image: url(${props.backgroundImage});`}

  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
`

const ReadMore = styled(Link)`
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-family: "MissionGothicBold", sans-serif;
  transition: 0.2s all linear;
  width: 100%;
  display: block;
  color: white;

  :hover {
    opacity: 0.7;
  }
`

class IndexPage extends React.Component {
  render() {
    const frontPage = this.props.data.contentfulFrontpage
    const { video, styleVideo } = frontPage
    return (
      <Layout>
        <SEO
          title="The Freedom Collection"
          keywords={[`freedom`, `human rights`]}
          description={frontPage.introText}
        />
        <Video autoPlay muted loop playsInline>
          <source src={video.file.url} type="video/mp4" />
        </Video>
        <Container
          backgroundColor="#e8c7b0"
          centerItems
          padding="56px 0px"
          mobilePadding="32px 0px"
        >
          <div className="w-100">
            <div className="pt4 mb4 tc">
              <div className="db v-mid">
                <img
                  src={DearLeader}
                  width="250"
                  className="img"
                  alt="Dear Leader"
                />
              </div>
              <div className="db v-mid">
                <Introduction color="#fff">presents</Introduction>
              </div>
              <div className="db v-mid">
                <img
                  src={LogoWhite}
                  width="200"
                  className="img"
                  alt="The Freedom Collection"
                />
              </div>
            </div>
            <Paragraph color="#fff">{frontPage.introduction}</Paragraph>
          </div>
        </Container>
        <CardContainer>
          <LeaderScroller />
          <Swipe />
        </CardContainer>
        <Container
          backgroundColor="#c8d5c7"
          centerItems
          padding="56px 0px"
          mobilePadding="32px 0px"
        >
          <div className="w-100">
            <div className="tc pt4">
              <div className="tc dib v-mid">
                <img
                  src={LogoWhite}
                  width="200"
                  className="img"
                  alt="The Freedom Collection"
                />
              </div>
              <Introduction className="dib v-mid" color="#fff">
                {frontPage.header2}
              </Introduction>
            </div>
            <Paragraph color="#fff">{frontPage.introduction2}</Paragraph>
          </div>
        </Container>
        <Section minHeight="400" backgroundImage={Kilo}>
          <div className="w-100">
            <Title>
              <p className="w-100 tc z-max white">
                Designed in Denmark by Copenhagen-based award winning design
                studio Kilo.
              </p>
            </Title>
            <ReadMore to="/kilo">Learn about Kilo</ReadMore>
          </div>
        </Section>
        <Video autoPlay muted loop playsInline>
          <source src={styleVideo.file.url} type="video/mp4" />
        </Video>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    contentfulFrontpage(contentful_id: { eq: "1yIrh6KhWoW2pNiIsjjyJ8" }) {
      background {
        fixed(width: 1600, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
      video {
        file {
          url
        }
      }
      introText
      header
      introduction
      header2
      introduction2
      styleVideo {
        file {
          url
        }
      }
    }
  }
`
