import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const CardContent = styled.div`
  min-width: 95vw;
  width: 95vw;
  margin: 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  ${props => props.background && `background-image: url(${props.background});`}

  @media screen and (min-width: 30em) {
    min-width: 800px;
    width: 800px;
  }
`

const InnerCard = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  transition: 0.2s linear all;
  text-decoration: none;

  ${props =>
    props.hover &&
    `background-blend-mode: overlay;
    background: rgba(0, 0, 0, .3);`}
`

const TextContainer = styled.div`
  text-align: center;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding-bottom: 24px;
`

const Name = styled.div`
  text-align: center;
  font-size: 1rem;
  font-family: "MissionGothicBold", sans-serif;
  line-height: 1.5;
  margin-bottom 12px;
  color: #000;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  background: ${props => props.color || `#000`};
  max-width: 300px;
`

const Description = styled.div`
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: "MissionGothicBold", sans-serif;
  text-decoration: none;
  padding: 8px 16px;
  max-width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background: ${props => props.color || `#000`};
  color: #000;
`
class SingleItem extends React.Component {
  state = { hover: false }

  setHover = () => {
    this.setState({ hover: true })
  }

  removeHover = () => {
    this.setState({ hover: false })
  }

  render() {
    const { node } = this.props
    return (
      <CardContent
        background={node.image.fixed.src}
        onMouseOver={this.setHover}
        onMouseLeave={this.removeHover}
      >
        <Link to={`/${node.slug}`} className="link">
          <InnerCard hover={this.state.hover}>
            <TextContainer>
              <Name color={node.color}>
                {node.name} {node.lastName}
              </Name>
              <Description color={node.color}>
                {node.overview.overview}
              </Description>
            </TextContainer>
          </InnerCard>
        </Link>
      </CardContent>
    )
  }
}

export default SingleItem
