import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import SingleScrollItem from "./SingleScrollItem"

const Card = styled.section`
  min-width: 100%;
  min-height: 600px;
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: touch;
`

const Scroller = ({ data }) => (
  <Card>
    {data.allContentfulLeader.edges.map((leader, i) => (
      <SingleScrollItem key={i} {...leader} />
    ))}
  </Card>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulLeader {
          edges {
            node {
              name
              color
              lastName
              slug
              overview {
                id
                overview
              }
              image {
                fixed(width: 1600, quality: 100) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Scroller data={data} {...props} />}
  />
)
